<script>
import '@lottiefiles/lottie-player'
import { create as LottieInteractivityCreate } from '@lottiefiles/lottie-interactivity'
import dayjs from 'dayjs/esm'
import { ValidationObserver } from 'vee-validate'
import { reactive, ref } from 'vue'
import ui from '/~/core/ui'
import { formatDate } from '/~/utils/format/date'
import BaseAsidePage from '/~/components/base/aside-page/base-aside-page.vue'
import BaseButton from '/~/components/base/button/base-button.vue'
import BaseDatepicker from '/~/components/base/datepicker/base-datepicker.vue'
import BaseInput from '/~/components/base/input/base-input.vue'
import BaseSelect from '/~/components/base/select/base-select.vue'
import { useAddresses } from '/~/composables/addresses'
import { useForm } from '/~/composables/base/use-form'
import { useCms } from '/~/composables/cms/use-cms'
import { useLocalization } from '/~/composables/localization'
import { useUser } from '/~/composables/user'
import { useVirtualCards } from '/~/composables/virtual-cards'

export default {
  name: 'virtual-card-create',
  components: {
    BaseAsidePage,
    BaseButton,
    BaseDatepicker,
    BaseInput,
    BaseSelect,
    ValidationObserver,
  },
  setup() {
    const { user } = useUser()
    const { defaultAddress } = useAddresses()
    const { firstVirtualCard, createVirtualCard } = useVirtualCards()
    const localization = useLocalization()
    const { states, translate } = useLocalization()
    const { validationObserverRef } = useForm()
    const { ewalletLabels } = useCms()

    const isCreating = ref(false)
    const form = reactive({
      addressLine1: defaultAddress.value?.streetAddress,
      addressLine2: 'temp',
      addressLine3: 'temp',
      country: 'AU',
      city: '',
      dateOfBirth: formatDate('daymonthyearnumeric', user.value?.birthDate),
      firstName: user.value?.firstName,
      lastName: user.value?.lastName,
      postcode: defaultAddress.value?.postcode,
      state: defaultAddress.value?.state,
    })
    const backendErrors = reactive({})
    const stateOptions = states.value.map((state) => ({
      text: state.label,
      value: state.id,
    }))
    const countryOptions = [
      {
        text: 'Australia',
        value: 'AU',
      },
    ]

    return {
      firstVirtualCard,
      isCreating,
      form,
      backendErrors,
      stateOptions,
      countryOptions,
      localization,
      validationObserverRef,
      createVirtualCard,
      translate,
      ui,
      ewalletLabels,
    }
  },
  data() {
    return {
      lottieInteractivity: null,
    }
  },
  computed: {
    isFormValid() {
      return Boolean(this.validationObserverRef?.flags.valid)
    },
  },
  mounted() {
    if (!this.$refs.createVirtualCardLoader) {
      return
    }

    this.$refs.createVirtualCardLoader.addEventListener('load', () => {
      this.lottieInteractivity = LottieInteractivityCreate({
        player: '#create-virtual-card-loader',
        mode: 'chain',
        actions: [
          {
            state: 'loop',
            frames: [132, 198],
            transition: 'onComplete',
          },
        ],
      })
    })
  },
  methods: {
    startCompletedLoaderAnimation() {
      this.lottieInteractivity.actions.push({
        state: 'autoplay',
        frames: [198, 299],
      })
    },
    async onSubmit() {
      this.isCreating = true

      try {
        await this.createVirtualCard({
          ...this.form,
          dateOfBirth: dayjs(this.form.dateOfBirth, 'DD-MM-YYYY').toISOString(),
        })
        this.startCompletedLoaderAnimation()

        setTimeout(() => {
          this.$router.push({
            hash: `#profile-virtual-card/${this.firstVirtualCard.id}`,
          })
        }, 2000)
      } catch (error) {
        this.isCreating = false
        this.$notify({
          type: 'error',
          text: 'Something went wrong while creating a virtual card. Please try again.',
        })
      }
    },
  },
}
</script>

<template>
  <base-aside-page title="Create virtual card" no-padding>
    <div class="flex h-full w-full flex-col">
      <div
        class="relative flex w-full shrink-0 grow flex-col overflow-hidden bg-white px-5 pb-[30px] pt-2.5"
      >
        <div class="font-bold">Please complete the form below</div>
        <div class="mt-2.5">
          In order to continue and to get your very own
          {{ ewalletLabels.ewalletCash }} Card please complete this form.
        </div>
        <validation-observer
          v-slot="{ handleSubmit }"
          ref="validationObserverRef"
          slim
        >
          <form
            class="mt-[30px] flex grow flex-col"
            @submit.prevent="handleSubmit(onSubmit)"
          >
            <div class="grid grid-cols-2 gap-[30px]">
              <base-input
                v-model="form.firstName"
                :validation="{
                  rules: 'required',
                  name: 'First name',
                }"
                :error="backendErrors.firstName"
                :disabled="false"
                :nolabel="true"
                required
                name="firstName"
                entry-class="rounded h-12"
                placeholder="First name"
              />
              <base-input
                v-model="form.lastName"
                :validation="{
                  rules: 'required',
                  name: 'Last name',
                }"
                :error="backendErrors.lastName"
                :disabled="false"
                :nolabel="true"
                required
                name="lastName"
                entry-class="rounded h-12"
                placeholder="Last name"
              />
              <base-input
                v-model="form.addressLine1"
                :validation="{
                  rules: 'required',
                  name: 'Address',
                }"
                :error="backendErrors.addressLine1"
                :disabled="false"
                :nolabel="true"
                required
                name="addressLine1"
                class="col-span-2"
                entry-class="rounded h-12"
                placeholder="Address"
              />
              <base-select
                v-model="form.country"
                :validation="{
                  rules: 'required',
                  name: 'Country',
                }"
                :disabled="false"
                :error="backendErrors.country"
                :options="countryOptions"
                :nolabel="true"
                required
                name="country"
                entry-class="rounded h-12"
                placeholder="Country"
              />
              <base-select
                v-model="form.state"
                :validation="{
                  rules: 'required',
                  name: 'State',
                }"
                :disabled="false"
                :error="backendErrors.state"
                :options="stateOptions"
                :nolabel="true"
                required
                name="state"
                entry-class="rounded h-12"
                placeholder="State"
              />
              <base-input
                v-model="form.city"
                :validation="{
                  rules: 'required',
                  name: 'City',
                }"
                :error="backendErrors.city"
                :disabled="false"
                :nolabel="true"
                required
                name="city"
                entry-class="rounded h-12"
                placeholder="City"
              />
              <base-input
                v-model="form.postcode"
                :validation="{
                  rules: 'required',
                  name: translate('address.postCode'),
                }"
                :error="backendErrors.postcode"
                :disabled="false"
                :nolabel="true"
                required
                name="postcode"
                :label="translate('address.postcode')"
                entry-class="rounded h-12"
                :placeholder="translate('address.postCode')"
              />
              <base-datepicker
                v-model="form.dateOfBirth"
                :validation="{
                  rules: 'required',
                  name: 'Date of birth',
                  mode: ui.mobile ? 'eager' : 'aggressive',
                }"
                :disabled="false"
                :nolabel="true"
                name="dateOfBirth"
                :error="backendErrors.dateOfBirth"
                placeholder="Date of birth"
                format="DD-MM-YYYY"
                class="col-span-2"
                entry-class="rounded h-12"
                :append-to-body="false"
                icon="rec/calendar-line-filled"
                icon-class="text-eonx-neutral-600"
                icon-position="right"
                :popup-style="{
                  top: 0,
                  left: 'auto',
                  right: 0,
                }"
                :is-fullwidth="true"
              />
            </div>
            <base-button
              type="submit"
              class="mt-auto w-full"
              :disabled="!isFormValid"
            >
              Continue
            </base-button>
          </form>
        </validation-observer>
      </div>
    </div>
    <transition name="fade">
      <div
        v-show="isCreating"
        class="absolute left-0 top-0 flex h-full w-full flex-col items-center justify-center bg-white pb-16"
      >
        <lottie-player
          id="create-virtual-card-loader"
          ref="createVirtualCardLoader"
          src="https://assets4.lottiefiles.com/packages/lf20_Osxi2h.json"
          :style="{
            width: '202px',
            height: '202px',
          }"
        />
        <h3>Creating virtual card</h3>
        <div class="mt-2.5">This process might take up to a minute</div>
      </div>
    </transition>
  </base-aside-page>
</template>
